<template lang="pug">
TheBook(
	:title="earners.title"
	:endpoint="earners.endpoint"
	:path="earners.path"
	:left="earners.left"
	:leftSub="earners.leftSub"
)
</template>

<script>
import TheBook from '../components/TheBook'
import BOOKS from '../modules/constants/books'

export default {
	name: 'Earners',
	components: { TheBook },
	setup () {
		const { earners } = BOOKS

		return {
			earners
		}
	}
}
</script>
